import { Box, Stack, type BoxProps } from "@chakra-ui/react";
import { MessageRole } from "@prisma/client";
import React, { useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMount } from "react-use";
import { ClientOnly } from "~/components/atoms/ClientOnly";
import { ChatInput } from "~/components/organisms/ChatInput";
import { ChatSplash } from "~/components/organisms/ChatSplash";
import { useAuthContext } from "~/context/AuthContext";
import { useChat } from "~/hooks/use-chat";
import { useChatId } from "~/hooks/use-chat-id";
import { ChatMessages } from "./ChatMessages";

const ALLOWED_PUBLIC_ANSWERS = 3;

interface ChatProps extends BoxProps {}

export const Chat: React.FC<ChatProps> = ({ ...props }) => {
  const chatId = useChatId();
  const { isAuthenticated } = useAuthContext();
  const form = useForm<{ message: string }>();

  const { message, messages, isError, isLoading, ask } = useChat({
    chatId,
    isAuthenticated,
    onMessage() {
      scrollToBottom();
    },
    onError() {
      if (message) form.setValue("message", message, { shouldValidate: true });
      scrollToBottom();
    },
  });

  const messagesContainerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    // wait for rerender, then scroll to bottom
    setTimeout(() => {
      messagesContainerRef.current?.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }, 0);
  };

  useMount(() => {
    scrollToBottom();
  });

  const onMessage = (message: string) => {
    ask(message);
    scrollToBottom();
  };

  const isPublicChatDisabled =
    !isAuthenticated &&
    messages.filter((message) => message.role === MessageRole.assistant)
      .length >= ALLOWED_PUBLIC_ANSWERS;

  const errorMessage = isError
    ? "Noget gik galt. Prøv venligst igen!"
    : isPublicChatDisabled
    ? "Fortsæt med at chatte efter at have logget ind!"
    : undefined;
  const pendingMessage = isLoading || isError ? message : undefined;

  return (
    <Stack {...props}>
      <Box
        ref={messagesContainerRef}
        flex={1}
        overflowY="auto"
        overflowX="hidden"
      >
        {isAuthenticated ? (
          messages.length || pendingMessage || errorMessage || isLoading ? (
            <ChatMessages
              messages={messages}
              pendingMessage={pendingMessage}
              errorMessage={errorMessage}
              isLoading={isLoading}
            />
          ) : (
            <ChatSplash isAuthenticated={isAuthenticated} />
          )
        ) : (
          <>
            <ChatSplash isAuthenticated={isAuthenticated} />
            <ClientOnly>
              {messages.length || pendingMessage || errorMessage ? (
                <ChatMessages
                  messages={messages}
                  pendingMessage={pendingMessage}
                  errorMessage={errorMessage}
                />
              ) : null}
            </ClientOnly>
          </>
        )}
      </Box>
      <FormProvider {...form}>
        <ChatInput
          isLoading={isLoading}
          isDisabled={isPublicChatDisabled}
          onMessage={onMessage}
        />
      </FormProvider>
    </Stack>
  );
};
