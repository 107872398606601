import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@trpc/react-query";
import { usePlausible } from "next-plausible";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { useDisclosureContext } from "~/context/DisclosureContext";
import { CHAT_ID_QUERY_PARAM } from "~/hooks/use-chat-id";
import { usePublicChatStore } from "~/store/chat";
import { api } from "~/utils/api";

export const useChat = (options: {
  isAuthenticated?: boolean;
  chatId?: string;
  onMessage: () => unknown;
  onError: () => unknown;
}) => {
  const plausible = usePlausible();
  const publicChat = usePublicChat({
    onMessage: options.onMessage,
    onError: options.onError,
  });
  const protectedChat = useProtectedChat({
    chatId: options.chatId,
    onMessage: options.onMessage,
    onError: options.onError,
  });

  const ask = (message: string) => {
    plausible("chat:ask", { props: { message, chatId: options.chatId } });
    if (!options.isAuthenticated) {
      publicChat.ask(message);
    } else {
      protectedChat.ask(message);
    }
  };

  return {
    ...(options.isAuthenticated ? protectedChat : publicChat),
    ask,
  };
};

const usePublicChat = (options: {
  onMessage: () => unknown;
  onError: () => unknown;
}) => {
  const { selectCategoriesModal } = useDisclosureContext();
  const { messages, addMessage } = usePublicChatStore();
  const [
    messageToAskOnceCategoriesAreSet,
    setMessageToAskOnceCategoriesAreSet,
  ] = useState<string | null>(null);

  const askSKVPublic = api.chat.askSKVPublic.useMutation({
    onMutate() {
      options.onMessage();
    },
    onSuccess(data, variables) {
      addMessage({ text: variables.message, role: "user" });
      addMessage({
        text: data.text,
        role: "assistant",
        sources: data.sources.map((source) => ({
          ...source,
          id: `${source.url}${source.sectionId || ""}`,
        })),
      });
      options.onMessage();
      askSKVPublic.reset();
    },
    onError(error, variables) {
      if (error.data?.code === "TOO_MANY_REQUESTS") {
        addMessage({ text: variables.message, role: "user" });
        addMessage(
          {
            text: "Tyvärr är det många som ställer frågor just nu. Logga in för att fortsätta chatten!",
            role: "assistant",
          },
          { rateLimited: true }
        );
        askSKVPublic.reset();
      }
      options.onError();
    },
  });

  const ask = useCallback(
    (message: string) => {
      const { messages: history, categories } = usePublicChatStore.getState();
      if (!categories.length) {
        setMessageToAskOnceCategoriesAreSet(message);
        selectCategoriesModal.onOpen();
        return;
      }
      askSKVPublic.mutate({
        message,
        categories,
        history: history.map((a) => ({
          content: a.text,
          role: a.role,
        })),
      });
    },
    [askSKVPublic, selectCategoriesModal]
  );

  useEffect(() => {
    const unsub = usePublicChatStore.subscribe(
      (state) => state.categories,
      (newCategories) => {
        const message = messageToAskOnceCategoriesAreSet;
        if (newCategories.length && message) {
          ask(message);
          setMessageToAskOnceCategoriesAreSet(null);
        }
      }
    );

    return () => {
      unsub();
    };
  }, [messageToAskOnceCategoriesAreSet, ask]);

  return {
    message: askSKVPublic.variables?.message,
    messages,
    isLoading: askSKVPublic.isLoading,
    isError: askSKVPublic.isError,
    ask,
  };
};

const useProtectedChat = (options: {
  chatId?: string;
  onMessage: () => unknown;
  onError: () => unknown;
}) => {
  const router = useRouter();
  const queryClient = useQueryClient();

  const chat = api.chat.get.useQuery(
    { id: options.chatId as string },
    {
      enabled: !!options.chatId,
      onSuccess() {
        if (askSKV.isSuccess) askSKV.reset();
      },
    }
  );

  const askSKV = api.chat.ask.useMutation({
    onMutate() {
      options.onMessage();
    },
    async onSuccess(data, input) {
      if (!input.chatId)
        await router.replace(
          {
            query: {
              ...router.query,
              [CHAT_ID_QUERY_PARAM]: data.chatId,
            },
          },
          undefined,
          { shallow: true }
        );
      await queryClient.invalidateQueries(
        getQueryKey(api.chat.get, { id: data.chatId })
      );
      options.onMessage();
      await queryClient.invalidateQueries(getQueryKey(api.chat.list));
    },
    async onError() {
      await queryClient.invalidateQueries(getQueryKey(api.chat.list));
    },
  });

  const ask = (message: string) => {
    askSKV.mutate({ chatId: options.chatId, message });
  };

  const isMutatingCurrentChat = askSKV.variables?.chatId === options.chatId;

  return {
    messages: chat.data?.messages ?? [],
    message: askSKV.variables?.message,
    isLoading:
      (options.chatId && chat.isLoading) ||
      (isMutatingCurrentChat && askSKV.isLoading),
    isError: isMutatingCurrentChat && askSKV.isError,
    ask,
  };
};
