import { Stack } from "@chakra-ui/react";
import type {
  GetServerSidePropsContext,
  InferGetServerSidePropsType,
  NextPage,
} from "next";
import { ChatLayout } from "~/components/layouts/ChatLayout";
import { Chat } from "~/components/organisms/Chat";
import { getServerAuthSession } from "~/server/auth";

export const getServerSideProps = async ({
  req,
  res,
}: GetServerSidePropsContext) => {
  const session = await getServerAuthSession({ req, res });
  return {
    props: {
      isAuthenticated: !!session?.user,
    },
  };
};

const ChatPage: NextPage<
  InferGetServerSidePropsType<typeof getServerSideProps>
> = ({ isAuthenticated }) => {
  return (
    <ChatLayout isAuthenticated={isAuthenticated}>
      <Stack flex={1} overflow="hidden">
        <Chat flex={1} overflow="hidden" />
      </Stack>
    </ChatLayout>
  );
};

export default ChatPage;
