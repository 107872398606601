import {
  Badge,
  Box,
  HStack,
  Heading,
  Icon,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
  VStack,
  type BoxProps,
} from "@chakra-ui/react";
import { DocumentCategory } from "@prisma/client";
import React from "react";
import { AlertTriangle, HelpCircle, Zap } from "react-feather";
import { api } from "~/utils/api";

interface ChatSplashProps extends BoxProps {
  isAuthenticated: boolean;
}

export const ChatSplash: React.FC<ChatSplashProps> = ({
  isAuthenticated,
  ...props
}) => {
  const user = api.user.getMe.useQuery(undefined, {
    enabled: isAuthenticated,
  });

  return (
    <Stack
      minH="full"
      align="center"
      justify="center"
      p={4}
      spacing={8}
      color="blue.700"
      textAlign="center"
      divider={
        <StackDivider
          borderColor="blue.200"
          visibility={["hidden", "visible"]}
        />
      }
      maxW="container.md"
      mx="auto"
      {...props}
    >
      <HStack spacing={4}>
        <Heading
          opacity={isAuthenticated ? 0.9 : 1}
          display={["none", "block"]}
        >
          <Text as="span" fontWeight="bold">
            Skat
          </Text>
          <Text as="span" fontWeight="normal">
            GPT
          </Text>
        </Heading>
        <Badge colorScheme="yellow" fontSize="lg" px={2}>
          2024
        </Badge>
      </HStack>
      <SimpleGrid
        columns={[1, 3]}
        spacing={[8, 4, null, 8]}
        opacity={isAuthenticated ? 0.8 : 1}
      >
        <Stack spacing={6}>
          <VStack spacing={3}>
            <Icon as={Zap} fontSize="2xl" />
            <Heading as="h3" fontSize="lg">
              Hvad er SkatGPT?
            </Heading>
          </VStack>
          <Stack as="ul" listStyleType="none">
            <Box
              as="li"
              p={2}
              bg="white"
              border="1px"
              borderColor="blue.200"
              rounded="lg"
              boxShadow="sm"
            >
              Hjælper dig med spørgsmål om skat og selvangivelse
            </Box>
            <Box
              as="li"
              p={2}
              border="1px"
              borderColor="blue.200"
              bg="white"
              rounded="lg"
              boxShadow="sm"
            >
              Bruger aktuel information fra Skattestyrelsen
            </Box>
            <Box
              as="li"
              p={2}
              border="1px"
              borderColor="blue.200"
              bg="white"
              rounded="lg"
              boxShadow="sm"
            >
              Tilpasser svarene efter dine oplysninger
            </Box>
          </Stack>
        </Stack>
        <Stack spacing={6}>
          <VStack spacing={3}>
            <Icon as={HelpCircle} fontSize="2xl" />
            <Heading as="h3" fontSize="lg">
              Hvordan virker det?
            </Heading>
          </VStack>
          <Stack as="ul" listStyleType="none">
            <Box
              as="li"
              p={2}
              border="1px"
              borderColor="blue.200"
              bg="white"
              rounded="lg"
              boxShadow="sm"
            >
              {
                'Spørg om hvad du vil, for eksempel "Hvad er det maksimale kørselsfradrag?"'
              }
            </Box>
            <Box
              as="li"
              p={2}
              border="1px"
              borderColor="blue.200"
              bg="white"
              rounded="lg"
              boxShadow="sm"
            >
              SkatGPT giver dig et svar
            </Box>
            <Box
              as="li"
              p={2}
              border="1px"
              borderColor="blue.200"
              bg="white"
              rounded="lg"
              boxShadow="sm"
            >
              Du får også links, der fører dig direkte til informationen
            </Box>
          </Stack>
        </Stack>
        <Stack spacing={6}>
          <VStack spacing={3}>
            <Icon as={AlertTriangle} fontSize="2xl" />
            <Heading as="h3" fontSize="lg">
              At tænke på
            </Heading>
          </VStack>
          <Stack as="ul" listStyleType="none">
            <Box
              as="li"
              p={2}
              border="1px"
              borderColor="blue.200"
              bg="white"
              rounded="lg"
              boxShadow="sm"
            >
              Svarene bør ikke betragtes som juridisk rådgivning.
            </Box>
            <Box
              as="li"
              p={2}
              border="1px"
              borderColor="blue.200"
              bg="white"
              rounded="lg"
              boxShadow="sm"
            >
              SkatGPT er ikke associeret med Skattestyrelsen
            </Box>
            <Box
              as="li"
              p={2}
              border="1px"
              borderColor="blue.200"
              bg="white"
              rounded="lg"
              boxShadow="sm"
            >
              Vær opmærksom, da forkerte oplysninger kan genereres
            </Box>
          </Stack>
        </Stack>
      </SimpleGrid>
      {user.data ? (
        <HStack as="ul" listStyleType="none">
          {user.data.categories.map(({ category }) => (
            <Box
              as="li"
              key={category}
              p={2}
              bg="yellow.50"
              border="1px"
              borderColor="yellow.400"
              rounded="lg"
              boxShadow="sm"
              color="yellow.700"
              noOfLines={1}
            >
              {
                {
                  [DocumentCategory.borger]: "Borger",
                  [DocumentCategory.erhverv]: "Erhverv",
                }[category]
              }
            </Box>
          ))}
        </HStack>
      ) : null}
    </Stack>
  );
};
